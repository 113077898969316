const RangeFather = [
    {
        image: "https://m.media-amazon.com/images/I/81C4qLsL+1L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81P96UmjTGL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0D45SJ99D",
        title: "I Love Dad Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: true,
    },
    {
        image: "https://m.media-amazon.com/images/I/81859foKadL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81lz5bGmdEL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0D45R3CB7",
        title: "Happy Father's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: true,
    },
    {
        image: "https://m.media-amazon.com/images/I/812K7mbPpfL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81h5KYt2DmL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0D45S9T9J",
        title: "Number 1 Dad Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
];

RangeFather.forEach((item, i, self) => self[i].collection = "father")

export default RangeFather;
