import React from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import BackgroundNotFound from "../assets/images/backgrounds/not-found.jpg";
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <Row className="h-100 not-found">
            <Col>
                <Card className="h-100 position-relative" style={{backgroundImage: `url(${BackgroundNotFound})`}}>
                    <CardTitle tag="h1" className="border-bottom p-3 mb-0 text-center">404</CardTitle>
                    <CardBody className="px-4 py-0">
                        <p className="mt-4">Oh no! Cookies Not Found!</p>
                        <p className="mt-4"><Link to="/" className="nav-link">Home Page</Link></p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default NotFound;
