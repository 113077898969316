import {createRoot} from 'react-dom/client';

import App from "./App";

import "./assets/scss/style.scss";

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(<App/>);
