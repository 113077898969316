const RangeValentine = [
    {
        image: "https://m.media-amazon.com/images/I/81slRAd68OL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81BeHmmglnL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR81MVY4",
        title: "I Love You Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81P3ABvxNiL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81Em+93Y9QL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR833W6S",
        title: "Heart Eyes Emoji Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81blQ+3dkcL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/818aa1PpgbL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR82PK8M",
        title: "Aubergine Eggplant Funny Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81uilAs9nHL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81SR1UiomaL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR829X32",
        title: "Heart Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81KiDoKsAkL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81u9rw6mHnL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR82N51C",
        title: "Kiss Me Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81a7hiKxLoL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81Dt9M0hF6L._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR81NGQS",
        title: "Hearts Valentine's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: true,
    },
];

RangeValentine.forEach((item, i, self) => self[i].collection = "valentine")

export default RangeValentine;
