const RangeEveryday = [
    {
        image: "https://m.media-amazon.com/images/I/81ud9DR6h2L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81wBzJVjRNL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCV4J83",
        title: "It's A Girl Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: true,
    },
    {
        image: "https://m.media-amazon.com/images/I/816x55Kf8fL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81QJoobdkdL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCQ3GGQ",
        title: "It's A Boy Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: true,
    },
    {
        image: "https://m.media-amazon.com/images/I/81J2Te3O3tL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81SKEINfoZL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCTB6Y9",
        title: "Happy Anniversary Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81iDZf3I-TL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81DI+HogmnL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCRVYW6",
        title: "Well Done Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81YWYMP6YJL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81q1qXacqhL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CQMS2HXF",
        title: "Good Luck Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/819ZqrCVH6L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81tXppY0SeL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CQMKFL6S",
        title: "Thank You Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/816rJurOs-L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81zQX25EPvL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CQMTVD58",
        title: "Happy Birthday Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
];

RangeEveryday.forEach((item, i, self) => self[i].collection = "everyday")

export default RangeEveryday;
