import {Container} from "reactstrap";

import Header from "./../components/Header";

const FullLayout = ({children}) => {
    return (
        <main>
            <Header/>
            <div className="pageWrapper d-lg-flex">
                <div className="contentArea fixedTopbar">
                    <Container className="p-4" fluid>
                        {children}
                    </Container>
                </div>
            </div>
        </main>
    );
};

export default FullLayout;
