import {Route, Routes,} from 'react-router-dom';

import Home from "./views/Home";
import Items from "./views/Items";
import About from "./views/About";
import NotFound from "./views/NotFound";

const Router = () => (
    <Routes>
        <Route path="/" element={<Home/>} exact/>
        <Route path="/about" element={<About/>} exact/>
        <Route path="/all" element={<Items/>} exact/>

        {/* Collections */}
        <Route path="/accessories" element={<Items filter="accessories"/>} exact/>
        <Route path="/christmas" element={<Items filter="christmas"/>} exact/>
        <Route path="/everyday" element={<Items filter="everyday"/>} exact/>
        <Route path="/mother" element={<Items filter="mother"/>} exact/>
        <Route path="/newyear" element={<Items filter="newyear"/>} exact/>
        <Route path="/valentine" element={<Items filter="valentine"/>} exact/>
        <Route path="/easter" element={<Items filter="easter"/>} exact/>
        <Route path="/father" element={<Items filter="father"/>} exact/>

        <Route path="*" element={<NotFound/>}/>
    </Routes>
);

export default Router;
