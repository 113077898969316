import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

const About = () => {
    return (
        <Row>
            <Col>
                <Card>
                    <CardTitle tag="h3" className="border-bottom p-3 mb-0 text-center">About Us</CardTitle>
                    <CardBody className="px-4 py-0">
                        <p className="mt-4">
                            Our goal is to make cake decorating fun and exciting.
                            We sell cookie stamps, embossers, and other tools to help every baker achieve the
                            perfect decoration.
                            Every collection gets regularly updated and we add new designs all the time.
                            All our products are available on Amazon to guarantee fast and free delivery for
                            every order.
                        </p>
                        <p className="mt-4">
                            All our items are made in the UK with eco-friendly plastic
                        </p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default About;
