import {useState} from "react";
import {Link} from "react-router-dom";
import {
    Navbar,
    Collapse,
    Nav,
    NavItem,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
} from "reactstrap";

import LogoText from "../assets/images/logo-text-white.png";

const Header = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

    const toggleMenu = () => setMenuOpen((prevState) => !prevState);

    return (
        <Navbar color="primary" dark expand="md" className="fix-header">
            <div className="d-flex align-items-center">
                <div className="d-lg-block d-none me-5 pe-3">
                    <Link to="/">
                        <img src={LogoText} alt="The Cookie Factory" className="logo"/>
                    </Link>
                </div>
                <NavbarBrand href="/">
                    <img src={LogoText} alt="The Cookie Factory" className="logo d-lg-none"/>
                </NavbarBrand>
            </div>
            <div className="hstack gap-2">
                <Button
                    color="primary"
                    size="sm"
                    className="d-sm-block d-md-none"
                    onClick={toggleMenu}
                >
                    <i className={`bi ${menuOpen ? `bi-x` : `bi-three-dots-vertical`}`}/>
                </Button>
            </div>

            <Collapse navbar isOpen={menuOpen}>
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <Link to="/all" className="nav-link">All Cookie Stamps</Link>
                    </NavItem>
                    <NavItem>
                        <Link to="/father" className="nav-link">Father's Day</Link>
                    </NavItem>
                    <NavItem>
                        <Link to="/everyday" className="nav-link">Everyday</Link>
                    </NavItem>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} inNavbar nav>
                        <DropdownToggle caret nav>Other Collections</DropdownToggle>
                        <DropdownMenu end>
                            <Link to="/christmas" className="dropdown-item" onClick={toggleDropdown}>Christmas</Link>
                            <Link to="/newyear" className="dropdown-item" onClick={toggleDropdown}>New Year</Link>
                            <Link to="/valentine" className="dropdown-item" onClick={toggleDropdown}>Valentine's Day</Link>
                            <Link to="/mother" className="dropdown-item" onClick={toggleDropdown}>Mother's Day</Link>
                            <Link to="/easter" className="dropdown-item" onClick={toggleDropdown}>Easter</Link>
                            <Link to="/accessories" className="dropdown-item" onClick={toggleDropdown}>Accessories</Link>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                        <Link to="/about" className="nav-link">About Us</Link>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default Header;
