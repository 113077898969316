const RangeEaster = [
    {
        image: "https://m.media-amazon.com/images/I/61siNEZtMdL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81WBbN6YUdL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CXRMJWG7",
        title: "Happy Easter Eggs Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/61iWNCqyb5L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81Xh4ODTgIL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCSSTV1",
        title: "Bunny Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/61EbHKw1Z5L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81Zd6gBdI2L._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CTCQ3FNG",
        title: "Happy Easter Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    }
];

RangeEaster.forEach((item, i, self) => self[i].collection = "easter")

export default RangeEaster;
