const RangeChristmas = [
    {
        image: "https://m.media-amazon.com/images/I/71VBjPjvtyL._AC_SL1361_.jpg",
        imageover: "https://m.media-amazon.com/images/I/814u8eVAOTL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLS8Y5RV",
        title: "Cane Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/716RWWlDDVL._AC_SL1327_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81gA0tkigDL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLS7BWKC",
        title: "Decorated Tree Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/71ntt-145+L._AC_SL1257_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81OZolrGqJL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLS8L8W2",
        title: "Deer Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/71T98GwtxCL._AC_SL1296_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81E4zDkUTIL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSH1X33",
        title: "Let It Snow Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/713b3-XXOhL._AC_SL1290_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81Ysj+qn6kL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSF66DK",
        title: "Merry Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/71EdpcLO3IL._AC_SL1331_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81GhATbv2qL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSH31C3",
        title: "Santa Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/71IhQ8wOptL._AC_SL1333_.jpg",
        imageover: "https://m.media-amazon.com/images/I/8179Q+JLUXL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSGF8ZC",
        title: "Snowflake Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/714C7RoUOoL._AC_SL1369_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81ZouFcoAzL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSZ7THZ",
        title: "Snowman Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/714Lu+X+OqL._AC_SL1327_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81kFEKjnKnL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CLSQCNGZ",
        title: "Tree Christmas Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
];

RangeChristmas.forEach((item, i, self) => self[i].collection = "christmas")

export default RangeChristmas;
