const RangeAccessories = [
    {
        image: "https://m.media-amazon.com/images/I/71uUJwA1I2L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/71sttcBFoYL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CQJ1783D",
        title: "Rush Stamp Handle for Cookie Stamps",
        subtitle: "£6.99",
        featured: false,
    },
];

RangeAccessories.forEach((item, i, self) => self[i].collection = "accessories")

export default RangeAccessories;
