const RangeMother = [
    {
        image: "https://m.media-amazon.com/images/I/81eaD43ookL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/810pP4dJubL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR9G6YX8",
        title: "Happy Mother's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81LF6hJra7L._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81I9raIJb6L._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR9F2QQT",
        title: "Best Mum Ever Mother's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/818TXSi4rWL._AC_SX679_.jpg",
        imageover: "https://m.media-amazon.com/images/I/81C6LBKucrL._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CR9FTNH8",
        title: "Mum Mother's Day Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
];

RangeMother.forEach((item, i, self) => self[i].collection = "mother")

export default RangeMother;
