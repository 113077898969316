import React, {useState} from 'react';
import {
    Col,
    Row,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from "reactstrap";
import {Link} from "react-router-dom";

import Items from "./Items";

// Backgrounds must be 1200x400px
import BackgroundValentine from "../assets/images/backgrounds/valentine.jpg";
import BackgroundMother from "../assets/images/backgrounds/mother.jpg";
import BackgroundFather from "../assets/images/backgrounds/father.jpg";
import BackgroundEveryday from "../assets/images/backgrounds/everyday.jpg";

const items = [
    {
        src: BackgroundFather,
        header: `Best Father's Day Stamps`,
        text: <Link to="/mother" className="nav-link">Shop Now</Link>,
        alt: '',
        key: 1,
    },
    // {
    //     src: BackgroundValentine,
    //     header: 'New Valentine Collection',
    //     text: <Link to="/valentine" className="nav-link">Shop Now</Link>,
    //     alt: '',
    //     key: 1,
    // },
    // {
    //     src: BackgroundMother,
    //     header: `Best Mother's Day Stamps`,
    //     text: <Link to="/mother" className="nav-link">Shop Now</Link>,
    //     alt: '',
    //     key: 2,
    // },
    {
        src: BackgroundEveryday,
        header: 'New Everyday Stamps',
        text: <Link to="/everyday" className="nav-link">Shop Now</Link>,
        alt: '',
        key: 2,
    },
];

const Home = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img src={item.src} alt={item.alt}/>
                <CarouselCaption
                    captionHeader={item.header}
                    captionText={item.text}
                />
            </CarouselItem>
        );
    });

    return (
        <div>
            <Row>
                <Col lg="6" className="text-center text-lg-start">Free Next Day Delivery with Amazon Prime</Col>
                <Col lg="6" className="text-center text-lg-end">UK Best Cake Decorating Equipment</Col>
            </Row>
            <Row>
                <Col className="py-3">
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        <CarouselIndicators
                            items={items}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                        />
                        {slides}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                        />
                    </Carousel>
                </Col>
            </Row>
            <Row>
                <Items featured/>
            </Row>
        </div>
    );
};

export default Home;
