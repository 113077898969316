import {
    Row,
    Col,
} from "reactstrap";

import Item from "../components/Item";
import Range from "../data/Range";

const Items = ({filter, featured}) => {
    let items = Range;

    if (filter && filter !== "") {
        items = items.filter(i => i.collection === filter);
    }

    if (featured) {
        items = items.filter(i => i.featured === true);
    }

    return (
        <div className="items">
            <Row>
                {items.map((i, index) => (
                    <Col sm="6" lg="6" xl="3" key={index} className="item">
                        <Item
                            image={i.image}
                            imageover={i.imageover}
                            title={i.title}
                            subtitle={i.subtitle}
                            href={i.href}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Items;
