import RangeAccessories from "./RangeAccessories";
import RangeChristmas from "./RangeChristmas";
import RangeEveryday from "./RangeEveryday";
import RangeMother from "./RangeMother";
import RangeNewYear from "./RangeNewYear";
import RangeValentine from "./RangeValentine";
import RangeEaster from "./RangeEaster";
import RangeFather from "./RangeFather";

const Range = [].concat(
    // coming soon events
    RangeFather,
    // always middle
    RangeEveryday,
    // future events
    RangeChristmas,
    RangeNewYear,
    RangeValentine,
    RangeMother,
    RangeEaster,
    // always last
    RangeAccessories,
);

export default Range;
