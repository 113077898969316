import {BrowserRouter} from "react-router-dom";

import Router from "./Router";
import FullLayout from "./layouts/FullLayout";

const App = () => (
    <BrowserRouter>
        <FullLayout>
            <Router/>
        </FullLayout>
    </BrowserRouter>
);

export default App;
