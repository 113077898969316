const RangeNewYear = [
    {
        image: "https://m.media-amazon.com/images/I/81Q1MJcta9L._AC_SX679_.jpg", // no stamped image
        imageover: "https://m.media-amazon.com/images/I/81Q1MJcta9L._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CNZZRCNT",
        title: "Happy New Year Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
    {
        image: "https://m.media-amazon.com/images/I/81Wb7QZSS4L._AC_SX679_.jpg", // no stamped image
        imageover: "https://m.media-amazon.com/images/I/81Wb7QZSS4L._AC_SX679_.jpg",
        href: "https://www.amazon.co.uk/dp/B0CP11CK54",
        title: "Fireworks Cookie Stamp Embosser",
        subtitle: "£3.99",
        featured: false,
    },
];

RangeNewYear.forEach((item, i, self) => self[i].collection = "newyear")

export default RangeNewYear;
